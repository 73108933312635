import { Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const EpicToaster = ({ theme, ...props }: ToasterProps) => {
	return (
		<Sonner
			richColors
			theme={theme}
			// className="toaster group"
			// toastOptions={{
			// 	classNames: {
			// 		toast:
			// 			'group toast',
			// 		description: 'group-[.toast]:text-muted-foreground',
			// 		actionButton:
			// 			'group-[.toast]:bg-black group-[.toast]:text-white',
			// 		cancelButton:
			// 			'group-[.toast]:bg-transparent group-[.toast]:text-black',
			// 	},
			// }}
			{...props}
		/>
	)
}

export { EpicToaster }
