import { type SEOHandle } from '@nasa-gcn/remix-seo'
import { type Permission, type Role, type User } from '@prisma/client'
import {
	json,
	type LoaderFunctionArgs,
	type HeadersFunction,
	type LinksFunction,
	type MetaFunction,
} from '@remix-run/node'
import {
	Links,
	Meta,
	Outlet,
	redirect,
	Scripts,
	ScrollRestoration,
	useLoaderData,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { EpicProgress } from '#app/components/progress-bar.tsx'
import { useToast } from '#app/components/toaster.tsx'
import { href as iconsHref } from '#app/components/ui/icon.tsx'
import { EpicToaster } from '#app/components/ui/sonner.tsx'
// import { useTheme } from '#app/routes/resources+/theme-switch.tsx'
import tailwindStyleSheetUrl from '#app/styles/tailwind.css?url'
import { themeColor } from '#app/styles/theme.ts'
import {
	getUserId,
	isAnonymousUserId,
	logout
} from '#app/utils/auth.server.ts'
import { buildStructuredImage } from '#app/utils/build-structured-image.server'
import { ClientHintCheck, getHints } from '#app/utils/client-hints.tsx'
import { prisma } from '#app/utils/db.server.ts'
import { getEnv } from '#app/utils/env.server.ts'
import { getCdnDomain } from '#app/utils/get-cdn-domain.server.ts'
import { honeypot } from '#app/utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl } from '#app/utils/misc.tsx'
import { useNonce } from '#app/utils/nonce-provider.ts'
import { getTheme } from '#app/utils/theme.server.ts'
import { makeTimings, time } from '#app/utils/timing.server.ts'
import { getToast } from '#app/utils/toast.server.ts'
import { getAnonymousToken } from './utils/session.server'
import { type StructuredImage } from './utils/structured-image'

export const handle: SEOHandle = {
	getSitemapEntries: () => null,
}

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		{ rel: 'preload', href: 'https://boringnotes-app.b-cdn.net/logo_light_200.avif', as: 'image' },
		{
			rel: 'alternate icon',
			type: 'image/png',
			href: '/favicons/favicon-32x32.png',
		},
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		{ rel: 'icon', type: 'image/png', href: '/favicons/favicon-32x32.png' },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
		{ rel: 'preload', as: 'style', href: 'https://fonts.googleapis.com/css2?family=Space+Mono:wght@200;300;400;500;600;700&family=DM+Sans:wght@200;300;400;500;600;700&display=swap' },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'Boring Notes' : 'Error | Boring Notes' },
		{ name: 'description', content: `Keep your thoughts, ideas, notes, and more, automatically organized.` },
		{ name: 'theme-color', content: `${themeColor(data?.requestInfo.userPrefs.theme)}` },
	]
}

export type AppUser = Omit<User, 'image' | 'createdAt' | 'updatedAt' | 'password'> & {
	image: StructuredImage | null
	createdAt: Date | string
	updatedAt: Date | string
	roles?: (Role & { permissions: Permission[] })[]
}

export async function action() {
	return redirect('/')
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})
	const { setAnonymousTokenCookie } = await getAnonymousToken(request)

	const user = userId
		? await time(
			() =>
				prisma.user.findUniqueOrThrow({
					select: {
						id: true,
						name: true,
						username: true,
						email: true,
						image: { include: { upload: true } },
						roles: {
							select: {
								name: true,
								permissions: {
									select: { entity: true, action: true, access: true },
								},
							},
						},
					},
					where: { id: userId },
				}),
			{ timings, type: 'find user', desc: 'find user in root' },
		)
		: null

	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}

	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()
	const cdnDomain = getCdnDomain()

	return json(
		{
			user: {
				...user,
				image: user?.image?.upload
					? buildStructuredImage({ id: user.image.id, altText: user.image.altText, upload: user.image.upload })
					: null,
			} as AppUser,
			isAnonymousUser: userId ? isAnonymousUserId(userId) : true,
			cdnDomain,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
				{ 'set-cookie': setAnonymousTokenCookie },
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

function Document({
	children,
	nonce,
	// theme = 'light',
	env = {},
	allowIndexing = true,
}: {
	children: React.ReactNode
	nonce: string
	// theme?: Theme
	env?: Record<string, string>
	allowIndexing?: boolean
}) {
	return (
		<html lang="en">
			<head>
				<ClientHintCheck nonce={nonce} />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1,viewport-fit=cover" />
				<Meta />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
			</head>
			<body>
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration
					nonce={nonce}
					getKey={(location) => location.pathname}
				/>
				<Scripts nonce={nonce} />
			</body>
		</html >
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	// const user = useOptionalUser()
	// const theme = useTheme()
	// const matches = useMatches()
	// const isOnSearchPage = matches.find((m) => m.id === 'routes/users+/index')
	// const searchBar = isOnSearchPage ? null : <SearchBar status="idle" />
	const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false'
	useToast(data.toast)

	return (
		<Document
			nonce={nonce}
			// theme={theme}
			allowIndexing={allowIndexing}
			env={data.ENV}
		>
			<Outlet />
			<EpicToaster />
			<EpicProgress />
		</Document>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	)
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
